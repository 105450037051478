<template>
  <div class="pdf" style="height: 100%;">
    <iframe width="100%" height="100%" :src="url + '#view=FitH,top&toolbar=0'"></iframe>
  </div>
</template>

<script>
export default {
  props: {
    pdfUrl: {
      type: String
    }
  },
  data () {
    return {
      fileFormatArr: [ 'doc', 'docx', 'xlsx', 'xls' ]
    };
  },
  computed: {
    url () {
      if (this.pdfUrl !== '') {
        let url;
        const fileFormat = this.pdfUrl.split('.');
        const index = this.fileFormatArr.findIndex((item) => item === fileFormat[fileFormat.length - 1]);
        if (index !== -1) {
          url = `https://view.officeapps.live.com/op/view.aspx?src=${this.pdfUrl}`;
        } else {
          url = this.pdfUrl;
        }
        return url;
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
